<template>
  <v-app id="inspire">
    <!-- mobile navigation drawer -->
    <v-navigation-drawer v-model="drawer" disable-resize-watcher app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> Menu </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item v-for="item in items" :key="item.title" :to="item.to" link>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Desktop header -->
    <v-app-bar
      app
      color="#fcb69f"
      dark
      src="mountains.jpg"
      scroll-target="#scrolling-techniques-2"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
        ></v-img>
      </template>

      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <span class="font-weight-light">Jacqueline</span>
        <span>Valdes</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <!-- Desktop navigation -->
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn text v-for="item in items" :key="item.title" :to="item.to" link>
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
    </v-app-bar>

    <!-- content -->
    <v-sheet
      id="scrolling-techniques-2"
      class="overflow-y-auto"
      max-height="2000"
    >
      <v-container style="height: 1000px">
        <v-main>
          <router-view></router-view>
        </v-main>
      </v-container>
    </v-sheet>

    <v-footer padless>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} — <strong>Jacqueline Valdes</strong>
        <div>
          <a
            href="https://www.linkedin.com/in/jacqueline-valdes-206691144/"
            class="icon brands fa-linkedin-in"
            ><span class="label">Linkedin</span></a>
        </div>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    drawer: false,
    items: [
      { title: "About", to: "/about" },
      { title: "Teaching", to: "/teaching" },
      { title: "Supervision", to: "/supervision" },
      { title: "Research", to: "/research" },
      { title: "Leadership", to: "/leadership" },
      { title: "Counseling", to: "/counseling" },
      { title: "Work Experience", to: "/experience" },
      { title: "Resources", to: "/resources" },
      { title: "Awards", to: "/awards" },
      { title: "Journal", to: "/journal" },
    ],
  }),
};
</script>
