import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import About from '../views/AboutView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'about',
    component: About
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/experience',
    name: 'experience',
    component: () => import('../views/WorkExperience.vue')
  },
  {
    path: '/teaching',
    name: 'teaching',
    component: () => import('../views/Teaching.vue')
  },
  {
    path: '/supervision',
    name: 'supervision',
    component: () => import('../views/Supervision.vue')
  },
  {
    path: '/research',
    name: 'research',
    component: () => import('../views/Research.vue')
  },
  {
    path: '/leadership',
    name: 'leadership',
    component: () => import('../views/Leadership.vue')
  },
  {
    path: '/counseling',
    name: 'counseling',
    component: () => import('../views/Counseling.vue')
  },
  {
    path: '/resources',
    name: 'resources',
    component: () => import('../views/Resources.vue')
  },
  {
    path: '/awards',
    name: 'awards',
    component: () => import('../views/Awards.vue')
  },
  {
    path: '/journal',
    name: 'journal',
    component: () => import('../views/Journal.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
