import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify,{
    iconfont: 'md',
    theme: {
        primary: '#A7FFEB'
    }
});

export default new Vuetify({
});
